:root {
  --label-color: #172b4d;
  --input-border-color: #dcdfe4;
  --input-placeholder-color: #b1b8c3;
  --input-arrow-color: #97a0af;
  --input-hover-color: var(--label-color);
  --input-focus-color: #156fe6;
  --input-match-color: #97a0af;
  --input-disabled-color: #dcdfe4;
  --input-error-color: #e62315;
}

.dropdown-container {
  display: block;
  text-align: left;
  position: relative;
  user-select: none;
}

.dropdown-container.boxed {
  min-width: 16.9375em;
  width: fit-content;
}

.dropdown-label {
  width: fit-content;
  margin: 0 0 0.5rem;
}

.dropdown-label__text {
  /* font-family: "Montserrat","Avenir"; */
  font-weight: 500;
  font-size: 0.875rem;
  letter-spacing: 0px;
  line-height: 1.25rem;
  color: var(--label-color);
}

.dropdown-text-container {
  position: relative;
}

.dropdown-text-container img {
  position: absolute;
  right: 2px;
  bottom: 5px;
  width: .75rem;
}

.dropdown-text-container .dropdown-text-container__text {
  /* font-family: "Avenir"; */
  /* font-family: "Montserrat","Avenir"; */
  font-weight: 400;
  font-size: 0.875rem;
  letter-spacing: 0;
  line-height: 1.25rem;
  color: var(--input-placeholder-color);
}

.dropdown-container .dropdown {
  border: 0.0625rem solid var(--input-border-color);
  border-radius: 0.25rem;
  padding: 0.5rem 0.9596875rem;
}

.dropdown-container .dropdown:hover:not(.has-focus):not(.has-error) {
  border-color: var(--input-hover-color) !important;
}

.dropdown-container .dropdown.has-focus {
  border-color: var(--input-focus-color) !important;
}

.dropdown-container .dropdown.is-disabled {
  background-color: var(--input-disabled-color) !important;
  pointer-events: none;
}

.dropdown-container .dropdown.is-active {
  border-color: var(--input-arrow-color) !important;
}

.dropdown-container .dropdown.has-error:not(.has-focus) {
  border-color: var(--input-error-color) !important;
}

.dropdown-container .dropdown.is-active .dropdown-text-container__text {
  color: var(--label-color) !important;
}

.dropdown-menu {
  position: absolute;
  left: 0;
  width: 100%;
  /* margin-top: 0.625rem; */
  margin-top: 0.255rem;
  background-color: #ffffff;
  box-shadow: 0 0 1.25rem #ccc;
  border-radius: 0.125rem;
  padding: 0.625rem 0;
  display: none;
  z-index: 99;
}

.dropdown-menu.is-visible {
  display: block;
}

.dropdown-menu li {
  padding: 0.625rem 1.25rem;
}

.dropdown-menu li:hover {
  background-color: #edeff1;
}

/* compact dropdown */
.dropdown-container.compact .dropdown-label__text,
.dropdown-container.compact .dropdown-text-container__text {
  font-size: 0.75rem;
}

.dropdown-container.compact .dropdown {
  border-top: unset !important;
  border-left: unset !important;
  border-bottom: 0.0625rem solid var(--input-border-color);
  border-right: unset !important;
  border-radius: 0rem !important;
  text-align: left;
  /*padding: 0.3125rem;*/
  display: inline-block;
  /*width: calc(100% - 3.125rem - 3.75rem);*/
  width: calc(100% - 1.5rem - 3.3125rem);
  position: relative;
}

.dropdown-container.compact .dropdown-label {
  display: inline-block;
  width: 3.3125rem;
  margin-right: 1.5rem;
}

.dropdown-container.compact .dropdown {
  padding: 0rem !important;
}

.dropdown-container.compact .dropdown-text-container::after {
  right: 0rem !important;
}

/* menu dropdown */
.dropdown-container.menu .dropdown {
  border: unset !important;
  width: fit-content !important;
  padding: 0.5rem 0;
}

.dropdown-container.menu .dropdown-menu {
  width: fit-content !important;
  border-radius: 0.625rem;
  left: -4.25rem;
}

/* .dropdown-container.menu .dropdown-menu::before {
  content: "";
  width: 0;
  height: 0;
  border-left: 0.4375rem solid transparent;
  border-right: 0.4375rem solid transparent;
  border-bottom: 0.625rem solid #ffffff;
  position: absolute;
  top: -0.625rem;
  left: 6.5625em;
} */

.dropdown-container.menu .dropdown-text-container__text {
  /* font-family: "Avenir"; */
  /* font-family: "Montserrat","Avenir"; */

  font-weight: 500;
  font-size: 0.875rem;
  letter-spacing: 0rem;
  line-height: 1.1875rem;
  color: var(--label-color);
}

.dropdown-container.menu .dropdown-text-container::after {
  height: 0.25rem;
  width: 0.5rem;
  top: 0.65rem;
  right: -0.75rem;
}

.dropdown-container .dropdown-text-container__input {
  border: none;
  caret-color: #156fe6;
  color: #172b4d;
  width: -webkit-fill-available;
}