/* Assuming you have a CSS file for this component, e.g., HelpVideos.css */

  
  .video-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two columns with equal width */
    grid-gap: 20px; /* Gap between grid items */
    margin-top: 20px;
  }
  
  .video-item {
    text-align: left;
  }
  .button-input {
    border-bottom: 0.5px solid #EDEFF1;
  }
  .button-input:focus {
    outline: none;
    border-bottom: 0.5px solid #172B4D;
  }
  
  .button-input:hover {
    outline: none;
    border-bottom: 0.5px solid #172B4D;
  }
  .custom-input {
    border: none;
    /* border-bottom: 0.5px solid #EDEFF1; */
    background-color: #18B5B7;
    color: #EDEFF1;
    padding: 5px;
    font-size: 16px;
    font-weight: normal !important;
    margin-right: 8px; /* Added margin-right to create spacing between input and button */
  }
  
  .custom-input::placeholder {
    color: #EDEFF1;
    font-weight: normal !important;
  }
  
  /* .custom-input:focus {
    outline: none;
    border-bottom: 0.5px solid #172B4D;
  }
  
  .custom-input:hover {
    outline: none;
    border-bottom: 0.5px solid #172B4D;
  }
   */