.data-table {
  text-align: left;
  font-family: "Montserrat","Avenir";
}

.data-table table {
  border-spacing: 0;
  transition: all 0.5s;
  border-collapse: inherit;
  width: 100%;
}

.data-table table .data-table-header-container {
  background-color: #F9F9FA;
  font-weight: 500;
  font-family: "Montserrat","Avenir";
  padding-top: 10px;
  padding-bottom: 9px;
}
/* 
.data-table table tbody {
  font-weight: 400;
  font-family: "Roboto";
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 18px;
  color: #172b4d;
  padding-top: 7px;
  padding-bottom: 7px;
} */

.data-table table tr .table-checkbox-container {
  display: flex;
  align-items: center;
}
.list-group thead tr th .table-checkbox-container {
  font-size: 14px;
  font-family: "Montserrat","Avenir";
  font-weight: 500;
  color: #172b4d;
}
.data-table table tr th .table-checkbox-container .dropdown-button {
  width: 8px;
  height: 4px;
  margin-bottom: 4px;
  margin-top: 8px;
}

.data-table table tr th .dropdown-container.menu .dropdown-text-container {
  min-width: 100%;
}

.data-table table tr th .dropdown-container .dropdown {
  padding: 0;
}

.data-table table .checkbox-container {
  margin: 0;
}

.data-table table .checkbox-container .checkbox-row {
  padding-left: 8px;
  display: inline;
  margin-left: 0;
}

.data-table table .checkbox-container .checkbox-row .checkmark {
  width: 1rem;
  height: 1rem;
  border: 1.5px solid #97a0af;
  top: 0px;
}

.data-table table .checkbox-container .checkbox-row .checkmark:after {
  left: 6px;
  top: 0.5px;
  width: 4px;
  height: 8px;
  border-width: 0 2px 2px 0;
}

.data-table table .checkbox-row input:checked ~ .checkmark {
  border: #156fe6;
}

.data-table table tbody {
  color: #172b4d;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.data-table table tbody tr.active :first-child {
  border-left-color: #156fe6;
}

.data-table table tbody tr.active :last-child {
  border-right-color: #156fe6;
}

.data-table table tbody tr.active td {
  border-color: #156fe6;
  border-right-color: #e0e3e7;
  border-left-color: #e0e3e7;
  background-color: #e5effc;
}

.data-table table tbody tr:hover {
  background-color: #f9f9fa;
  cursor: pointer;
}

.data-table table tbody .key-name:not(:first-child) {
  padding-left: 20px;
}

.data-table table tbody .key-name {
  color: #7c879b;
  text-transform: capitalize;
}

.data-table table th {
  text-transform: capitalize;
}

.data-table table th,
.data-table table td {
  border: 1px solid #dcdfe4;
  padding-right: 19px;
  padding-left: 19px;
}

.data-table table th:first-child,
.data-table table td:first-child {
  /* border: 1px solid #dcdfe4; */
  padding-right: 19px;
  padding-left: 11px;
}

.data-table table th {
  border-top: 1px solid #f9f9fa;
}

.data-table table th:first-child {
  border-left: 1px solid #f9f9fa;
}

.data-table table th:last-child {
  border-right: 1px solid #f9f9fa;
}

.data-table table th .dropdown-text-container {
  display: flex;
}
.data-table table th .dropdown-text-container img {
  position: relative;
  right: -3px;
  bottom: 0px;
  width: 8px;
}
.input-container.compact .input {
  background-color: transparent;
}
.table-filter-container {
  /* min-width: 150px; */
  /* min-width: 150px; */
  width: 100%;
  /* margin-left: -80px; */
  /* margin-bottom: -10px; */
}
