:root {
  --label-color: #172B4D;
  --label-required-asterisk-color: #e6156f;
  --input-instructions-color: #647188;
  --input-border-color: #dcdfe4;
  --input-placeholder-color: #b1b8c3;
  --input-arrow-color: #97a0af;
  --input-hover-color: var(--label-color);
  --input-focus-color: #18B5B7;
  --input-match-color: #97a0af;
  --input-disabled-color: #dcdfe4;
  --input-error-message: #e62315;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.input-container {
  display: block;
  text-align: left;
  position: relative;
  user-select: none;
  margin: 0.5rem 0;
}

.input-label {
  width: fit-content;
}

.input-label__text {
  font-family: "Roboto-medium";
  font-weight: 500;
  font-size: 0.875rem;
  letter-spacing: 0px;
  line-height: 1.25rem;
  color: var(--label-color);
}

.input-text-container .input-text-container__text {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 0.875rem;
  letter-spacing: 0;
  line-height: 1.25rem;
  color: var(--input-placeholder-color);
}

.input-container .input {
  border: 0.0625rem solid var(--input-border-color);
  border-radius: 0.25rem;
  padding: 0.5rem 0.9596875rem;
}

.input-container .input:hover:enabled:not(:focus),
.input-container .input:hover:enabled:not(:focus-within),
.input-container .input:hover:enabled:not(.has-error) {
  border-color: var(--input-hover-color) !important;
}

.input-container .input:focus {
  border-color: var(--input-focus-color) !important;
  outline: none;
  pointer-events: none;
}

.input-container .input.has-error,
.input-container .input.has-error:hover,
.input-container .input.has-error:focus {
  border-color: var(--input-error-message) !important;
}

.input-container .input.is-disabled {
  background-color: var(--input-disabled-color) !important;
  pointer-events: none;
}

.input-container.boxed .input-label {
  /* font-family: "Roboto", sans-serif; */
  font-weight: 400;
  font-size: 0.875rem;
  letter-spacing: 0;
  line-height: 1.25rem;
  margin: 0 0 0.5rem;
}

.input-container.boxed .input {
  padding: 0.7rem 0.9596875rem;
}

.input-container.boxed .input::placeholder {
  color: var(--input-placeholder-color);
}

/* compact input */

.input-container.compact .input-label__text,
.input-container.compact .input {
  font-size: 0.75rem;
}

.input-container.compact .input {
  line-height: 19px;
  border-top: unset !important;
  border-left: unset !important;
  border-bottom: 0.0625rem solid var(--input-border-color);
  border-right: unset !important;
  border-radius: 0rem !important;
  text-align: left;
  width: 100%;
  padding: 0rem !important;
}

.input-container.compact .input-wrapper {
  display: inline-block;
  width: calc(100% - 1rem - 4rem);
}

.input-container.compact .input-label {
  display: inline-block;
  width: 4rem;
  margin-right: 1rem;
  vertical-align: top;
}

.input-wrapper p {
  display: block;
  /* font-family: "Roboto"; */
  font-weight: 400;
  letter-spacing: 0px;
  font-size: 0.75rem;
  padding-top: 0.5rem;
}

.input-wrapper p.error-message {
  color: var(--input-error-message);
}

.input-container p.error-message {
  margin: 0;
}

.input-container .input-wrapper .link-icon {
  display: none;
}

.input-container.compact .input-wrapper.domain .icon-and-input-field-wrapper {
  position: relative;
}

.input-container.compact .input-wrapper.domain .link-icon {
  display: block;
  position: absolute;
  left: 0;
  bottom: 5px;
  width: 12px;
}

.input-container.compact .input-wrapper.domain input {
  text-indent: 20px;
}
.asterisk {
  color: var(--label-required-asterisk-color);
  margin-left: 2px;
}
.instructions {
  color: var(--input-instructions-color);
}
