@tailwind base;
@tailwind components;

@font-face {
    font-family: 'Avenir';
    font-weight: normal;
    /* src: local('Avenir'), url(./assets/fonts/AvenirNext-Regular.ttf) format('ttf'); */
    src: url('./assets/fonts/AvenirNext-Regular.ttf') ;
}

@font-face {
    font-family: 'Avenir';
    font-weight: 500;
    /* src: local('Avenir'), url(./assets/fonts/AvenirNext-Medium.otf) format('ttf'); */
    src:  url('./assets/fonts/AvenirNext-Medium.otf');
}

@font-face {
    font-family: 'Avenir';
    font-weight: 600;
    /* src: local('Avenir'), url(./assets/fonts/AvenirNext-DemiBold.ttf) format('ttf'); */
    src:url('./assets/fonts/AvenirNext-DemiBold.ttf');
}


@font-face {
    font-family: 'Avenir';
    font-weight: bold;
    /* src: local('Avenir'), url(./assets/fonts/AvenirNext-Bold.ttf) format('ttf'); */
    src:  url('./assets/fonts/AvenirNext-Bold.ttf');
}

@font-face {
    font-family: 'Montserrat';
    font-weight: normal;
    /* src: local('Avenir'), url(./assets/fonts/AvenirNext-Regular.ttf) format('ttf'); */
    src: url('./assets/fonts/Montserrat-Regular.ttf') ;
}

@font-face {
    font-family: 'Montserrat';
    font-weight: 500;
    /* src: local('Avenir'), url(./assets/fonts/AvenirNext-Medium.otf) format('ttf'); */
    src:  url('./assets/fonts/Montserrat-Medium.ttf');
}

@font-face {
    font-family: 'Montserrat';
    font-weight: 600;
    /* src: local('Avenir'), url(./assets/fonts/AvenirNext-DemiBold.ttf) format('ttf'); */
    src:url('./assets/fonts/Montserrat-SemiBold.ttf');
}


@font-face {
    font-family: 'Montserrat';
    font-weight: bold;
    /* src: local('Avenir'), url(./assets/fonts/AvenirNext-Bold.ttf) format('ttf'); */
    src:  url('./assets/fonts/Montserrat-Bold.ttf');
}

@tailwind utilities;