/* .overflownewScroller::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 1px rgba(0,0,0,0.1);
	background-color: transparent;
}

.overflownewScroller::-webkit-scrollbar
{
	width: 6px;
	background-color: transparent;
}

.overflownewScroller::-webkit-scrollbar-thumb
{
	background-color: transparent;
} */
@media only screen and (max-width:1024px) {
    .sidebar {
        width: 148px;
    }
    .rightContainer {
        width: calc(100% - 148px);
    }
    .tab\:searchbox {
        width: 248px;
    }
    .tab\:orginzation {
        width: calc(100% - 248px);
    }
    .tab\:listHeight {
        max-height: calc(100vh - 300px);
    }
}
@media only screen and (max-width:639px) {
    .sidebar {
        width: 100%;
    }
    .rightContainer {
        width: 100%;
    }
    .tab\:searchbox {
        width: 100%;
    }
    .tab\:orginzation {
        width: calc(100% - 248px);
    }
    .tab\:listHeight {
        max-height: calc(100vh - 300px);
    }
    .mobile\:w-full {
        width: 100%;
    }
}