.layoutContainer {
  display: flex;
  flex-direction: row;
}

@media screen and (max-width: 1023px) {
  .layoutContainer {
    flex-direction: column;
    flex-direction: column-reverse;
  }
}