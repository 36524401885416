:root {
  --unselected-color: #dfe3e6;
  /* --unselected-color: #172b4d; */
  --selected-color: #172b4d;

  --unselected-border-color: #dfe3e6;
  --filled-box-color: #18B5B7;

  --unselected-disabled-border-color: #dcdfe4;
  /* --unselected-disabled-bg-color: #edeff1; */
  --unselected-disabled-bg-color: #e7e9ed;
  --unselected-disabled-tick-color: #b1b8c3;
}

.checkbox-container {
  margin: 0.4rem;
  text-align: left;
}

.checkbox-row {
  display: block;
  position: relative;
  padding-left: 1.5rem;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 1rem;
}

.checkbox-row span,
.checkbox-row a {
  font-family: "Roboto-medium";
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0;
  color: var(--unselected-color);
}

.checkbox-row input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 1.4px;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: transparent;
  border: 1.5px solid var(--unselected-border-color);
  border-radius: 4px;
}

.checkbox-row input:checked~.checkmark {
  background-color: var(--filled-box-color);
  border-color: var(--filled-box-color);
  transition: 0.3s;
}

.checkbox-row input:checked:hover:enabled~.checkmark {
  border-color: var(--filled-box-color);
}

.checkbox-row input:hover:enabled~.checkmark {
  border-color: var(--unselected-color);
  transition: 0.3s;
}

.checkbox-row input:disabled~.checkmark {
  background-color: var(--unselected-disabled-bg-color);
  border: 1.5px solid var(--unselected-disabled-border-color);
  cursor: not-allowed;
}

.disabled-line {
  position: absolute;
  width: 100%;
  background-color: #afb8c4;
  height: 2px;
  top: 40%;
  left: 50%;
  cursor: not-allowed;
  z-index: 999;
  margin: auto;
}


.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox-row input:checked~.checkmark:after {
  display: block;
}

.checkbox-row .checkmark:after {
  left: 4px;
  top: 1px;
  width: 4px;
  height: 8px;
  border: solid white;
  border-width: 0 1.5px 1.5px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.checkbox-link a:link,
a:visited,
a:hover,
a:active {
  color: #156fe6;
  font-size: 14px;
}