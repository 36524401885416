:root {
  --color-table-border: #dcdfe4;
  --color-table-header-bg: #f9f9fa;
  --color-table-td: #172b4d;
  /* --color-table-td-tr-bg-active: #e5effc; */
  /* --color-table-td-tr-border-active: #156fe6; */
  --color-table-td-tr-bg-active: #f9f9fa;
  --color-table-td-tr-border-active: #e0e3e7;
  --color-table-td-tr-hover-bg: #f9f9fa;
  --color-table-td-tr-border: #e0e3e7;
  --color-table-td-key-name: #172B4D;
}

.data-table {
  text-align: left;
  /* font-family: 'Avenir'; */
  height: auto;
  overflow-y: scroll;
  /* font-family: "Roboto"; */
}

.data-table table {
  border-spacing: 0;
  border: 1px solid var(--color-table-border);
  /* border-bottom: none; */
  transition: all 0.5s;
  border-collapse: inherit;
  width: 100%;
}

.data-table table .data-table-header-container {
  background-color: var(--color-table-header-bg);
  /* font-weight: 500; */
  font-weight: normal;
  padding-top: 10px;
  padding-bottom: 9px;
}

.data-table table thead,
.data-table table tbody {
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 16px;
  color: var(--color-table-td);
  padding-top: 7px;
  padding-bottom: 7px;
}

.data-table table tr .table-checkbox-container {
  display: flex;
  align-items: center;
}

.data-table table tr th .table-checkbox-container .dropdown-button {
  width: 8px;
  height: 4px;
  margin-bottom: 4px;
  margin-top: 9px;
}

.data-table table .checkbox-container {
  margin: 0;
  height: 18px;
}

.data-table table .checkbox-container .checkbox-row {
  padding-left: 8px;
}

.data-table table .checkbox-container .checkbox-row .checkmark {
  width: 1rem;
  height: 1rem;
  border-radius: 3px;
  border: 1.5px solid #dfe3e6;
}

.data-table table .checkbox-container .checkbox-row .checkmark:after {
  left: 6px;
  top: 3px;
  width: 4px;
  height: 8px;
  border-radius: 3px;
  border-width: 0 2px 2px 0;
}

.data-table table .checkbox-row input:checked~.checkmark {
  border: var(--color-table-td-tr-border-active);
}

.data-table table tbody {
  color: var(--color-table-td);
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.data-table table tbody tr.active :first-child {
  border-left-color: var(--color-table-td-tr-border-active);
}

.data-table table tbody tr.active :last-child {
  border-right-color: var(--color-table-td-tr-border-active);
}

.data-table table tbody tr.active td {
  border-color: var(--color-table-td-tr-border-active);
  border-right-color: var(--color-table-td-tr-border);
  border-left-color: var(--color-table-td-tr-border);
  background-color: var(--color-table-td-tr-bg-active);
}

.data-table table tbody tr:hover {
  background-color: var(--color-table-td-tr-hover-bg);
  cursor: pointer;
}

.data-table table tbody .key-name:not(:first-child) {
  padding-left: 20px;
}

.data-table table tbody .key-name {
  color: var(--color-table-td-key-name);
  text-transform: capitalize;
}

.data-table table th,
.data-table table td {
  border-top: none;
  /* border: 1px solid var(--color-table-border); */
  /* border-bottom: 1px solid var(--color-table-border); */
  border-right: none;
  border-left: none;
  padding-top: 8px;
  padding-bottom: 8px;
}