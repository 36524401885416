:root {
  --color-li: #172b4d;
  --color-li-hover: #edeff1;
  --icon-hover-color: #ccc;
  --icon-color: #172b4d;
}

.three-Dot-vertical-menu-container {
  position: relative;
  display: inline-block;
}

.three-Dot-vertical-menu-container div {
  display: flex;
  border: none;
  /* visibility: hidden; */
  background: transparent;
  padding: 0;
  margin: 0;
  border-radius: 5px;
  width: 14px;
  height: 24px;
  align-self: center;
  justify-content: center;
  align-items: center;
}

.three-Dot-vertical-menu-container div:hover {
  visibility: visible;
  /* background: #ccc; */
}

.three-Dot-vertical-menu-container .button-hover {
  background: #ccc;
}

.three-Dot-vertical-menu-container ul {
  list-style: none;
  margin: 0;
  padding-left: 0;
}

.three-Dot-vertical-menu-container .dropdown-hide {
  display: none;
  opacity: 0;
}

.three-Dot-vertical-menu-container .dropdown {
  margin-top: 1px;
  border-radius: 6px;
  box-shadow: 0 0px 1px rgb(0 0 0 / 16%), 0px 0px 5px rgb(0 0 0 / 16%);
  text-align: left;
  opacity: 1;
  min-width: 6rem;
  padding: 5px 0;
  position: absolute;
  transition: all 0.5s ease;
}

.three-Dot-vertical-menu-container .dropdown div {
  padding: 8px 16px 8px 16px;
  /* color: var(--color-li); */
  font-weight: 400;
  clear: both;
  width: 100%;
  font-family: "Avenir";
  /* font-weight: 400; */
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
}

/* .three-Dot-vertical-menu-container .dropdown li:hover {
  background-color: var(--color-li-hover);
} */

.three-Dot-vertical-menu-container .dropdown a,
.three-Dot-vertical-menu-container .dropdown a:active,
.three-Dot-vertical-menu-container .dropdown a:hover,
.three-Dot-vertical-menu-container .dropdown a:visited {
  color: var(--color-li);
  text-decoration: none;
  text-transform: capitalize;
}

.three-Dot-vertical-menu-container .position-top-left {
  right: 0;
  top: -100px;
}

.three-Dot-vertical-menu-container .position-top-right {
  left: 0;
  top: -100px;
}

.three-Dot-vertical-menu-container .position-bottom-right {
  left: 0;
}

.three-Dot-vertical-menu-container .position-bottom-left {
  right: 0;
}
