:root {
    --form-control-color: #18B5B7;
}

#okta-sign-in.auth-container.main-container {
    width: 100%;
    box-shadow: none !important;
    border: none;
}

.okta-sign-in-header {
    /* display: none !important; */
}

.auth-header {
    border: none !important;
}

.auth-content {
    padding: 0px !important;
}

.o-form-head {
    display: none;
}

.o-form-label {
    font-family: 'Avenir' !important;
    color: #172B4D !important;
    font-weight: normal !important;
}

.o-form-label:after {
    content: '*';
    font-family: 'Avenir' !important;
    color: #FE4F4F !important;
    font-weight: normal !important;
}

.side-nav-bottom-links {
    display: block;
}

.auth-footer {
    display: none !important;
}

.okta-form-input-field {
    background-color: #fff;
    padding: 0px !important;
    border-width: 0px 0px 1px 0px !important;
    border-radius: 0 !important;
    border-color: #B1B8C3 !important;
}

.okta-form-input-field:hover {
    border-color: #647188 !important;
}

.okta-form-input-field:focus {
    box-shadow: none !important;
    border-bottom-color: #18B5B7 !important;
}

.o-form-button-bar {
    border: none !important;
    box-shadow: none !important;
}

.button-primary {
    background: none !important;
    text-transform: uppercase !important;
    font-weight: 600 !important;
    border-color: #18B5B7 !important;
    background-color: #18B5B7 !important;
}

.button-primary:hover {
    background-color: #31BDBF !important;
}

input[type="text"] {
    padding: 6px 0px !important;
}

input[type="password"] {
    padding: 6px 0px !important;
}

input[type="text"]:focus {
    box-shadow: none !important;
}

.focused-input {
    box-shadow: none !important;
}

.icon {
    /* display: none !important; */
}

.o-form-input-error>.icon {
    display: none !important;
}

.o-form-input-error {
    padding-left: 0px !important;
    color: #FE4F4F !important;
    font-family: 'Avenir' !important;
}

/* input[type="date"]::-webkit-datetime-edit-text,
input[type="date"]::-webkit-datetime-edit-month-field,
input[type="date"]::-webkit-datetime-edit-day-field,
input[type="date"]::-webkit-datetime-edit-year-field {
    color: #172B4D;
} */

input[type="date"]::-webkit-calendar-picker-indicator {
    background: url('../../assets/images/CalendarIcon.png');
    background-size: contain;
    background-repeat: no-repeat;
    object-fit: cover;
    bottom: 0;
    height: 14px;
    margin-top: 35px;
    width: 12px;
    color: transparent;
    cursor: pointer;
    height: auto;
    position: absolute;
    right: 0;
    top: 0;
    /* width: auto; */
}


/* regular text style */
input[type="date"].date-input--has-value::-webkit-datetime-edit-text,
input[type="date"].date-input--has-value::-webkit-datetime-edit-month-field,
input[type="date"].date-input--has-value::-webkit-datetime-edit-day-field,
input[type="date"].date-input--has-value::-webkit-datetime-edit-year-field {
    /* color: #172B4D !important; */
    color: #B1B8C3 !important;
}

[contentEditable=true]:empty:not(:focus):before {
    content: attr(data-text)  ;
    color:#c0b8c7;
  } 

.my-tooltip{
    white-space: nowrap;
}

.toggleButton {
    display: 'block';
}

@media screen and (max-width: 1023px) {
    .side-nav-bottom-links {
        display: none;
    }
    .toggleButton{
        display: none;
    }
  }